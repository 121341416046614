import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type WhiteLabelling = {
	businessLogo: string;
	primaryColor: string;
	secondaryColor: string;
	buttonShape: string;
	buttonStyle: string;
};

export interface InitialWhiteLabelling {
	currentTheme: WhiteLabelling;
	previousTheme: WhiteLabelling;
}

const initialState: InitialWhiteLabelling = {
	currentTheme: {
		businessLogo: '',
		primaryColor: '#206AA2',
		secondaryColor: '#E1EFF9',
		buttonShape: 'square',
		buttonStyle: 'filled',
	},

	previousTheme: {
		businessLogo: '',
		primaryColor: '#206AA2',
		secondaryColor: '#E1EFF9',
		buttonShape: 'square',
		buttonStyle: 'filled',
	},
};

export const whiteLabellingSlice = createSlice({
	name: 'whitelabelling',
	initialState,
	reducers: {
		updateWhiteLabellingCurrent: (state: InitialWhiteLabelling, action: PayloadAction<WhiteLabelling>) => {
			state.currentTheme.businessLogo = action?.payload?.businessLogo;
			state.currentTheme.primaryColor = action?.payload?.primaryColor;
			state.currentTheme.secondaryColor = action?.payload?.secondaryColor;
			state.currentTheme.buttonShape = action?.payload?.buttonShape;
			state.currentTheme.buttonStyle = action?.payload?.buttonStyle;
		},

		updateWhiteLabellingPreview: (state: InitialWhiteLabelling, action: PayloadAction<WhiteLabelling>) => {
			state.previousTheme.businessLogo = action?.payload?.businessLogo;
			state.previousTheme.primaryColor = action?.payload?.primaryColor;
			state.previousTheme.secondaryColor = action?.payload?.secondaryColor;
			state.previousTheme.buttonShape = action?.payload?.buttonShape;
			state.previousTheme.buttonStyle = action?.payload?.buttonStyle;
		},
		updateWhiteLabellingBusinessLogo: (
			state: InitialWhiteLabelling,
			action: PayloadAction<{ businessLogo: string }>
		) => {
			state.currentTheme.businessLogo = action?.payload?.businessLogo;
		},
		updateWhiteLabellingPrimaryColor: (
			state: InitialWhiteLabelling,
			action: PayloadAction<{ primaryColor: string }>
		) => {
			state.currentTheme.primaryColor = action?.payload?.primaryColor;
		},
		updateWhiteLabellingSecondaryColor: (
			state: InitialWhiteLabelling,
			action: PayloadAction<{ secondaryColor: string }>
		) => {
			state.currentTheme.secondaryColor = action?.payload?.secondaryColor;
		},
		updateWhiteLabellingButtonShape: (
			state: InitialWhiteLabelling,
			action: PayloadAction<{ buttonShape: string }>
		) => {
			state.currentTheme.buttonShape = action?.payload?.buttonShape;
		},
		updateWhiteLabellingButtonStyle: (
			state: InitialWhiteLabelling,
			action: PayloadAction<{ buttonStyle: string }>
		) => {
			state.currentTheme.buttonStyle = action?.payload?.buttonStyle;
		},
	},
});

export const {
	updateWhiteLabellingCurrent,
	updateWhiteLabellingPreview,
	updateWhiteLabellingBusinessLogo,
	updateWhiteLabellingButtonShape,
	updateWhiteLabellingPrimaryColor,
	updateWhiteLabellingSecondaryColor,
	updateWhiteLabellingButtonStyle,
} = whiteLabellingSlice.actions;

export default whiteLabellingSlice.reducer;
